<template>
  <div class="ele-body">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="充值记录列表" name="first">
        <el-card shadow="never">
          <!-- 搜索表单 -->
          <el-form :model="table.where" class="ele-form-search d-flex"
                   @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
            <el-form-item label="支付方式:" label-width="80px">
              <el-select v-model="table.where.type" placeholder="请选择" class="input150" clearable>
                <el-option  label="微信" value="1"/>
                <el-option label="支付宝" value="2"/>
              </el-select>
            </el-form-item>
            <el-form-item label="省:" label-width="35px">
              <el-select v-model="table.where.pid"  @change="handleChangeProv(table.where.pid)" placeholder='请选择省' class="input150" clearable>
                <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市:" label-width="35px">
              <el-select v-model="table.where.cid"  @change="handleChangeCity(table.where.cid)" placeholder='请选择市' class="input150" clearable>
                <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="县/区:" label-width="65px">
              <el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" class="input150" clearable>
                <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="消费金额(元):" label-width="80px">
              <el-input v-model="table.where.price" placeholder="请输入消费金额" class="input163" clearable/>
            </el-form-item>
            <el-form-item label="开始时间:" label-width="80px" >
              <el-date-picker style="width:163px" v-model="table.where.create_time" type="date" placeholder="选择日期" clearable>
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:" label-width="80px">
              <el-date-picker style="width:163px" v-model="table.where.end_time" type="date" placeholder="选择日期" class="mr-20" clearable>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
              <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
              <download class="ele-btn-icon"  label="导出" url="adminiaelist/adminRechargeExportExcel" :params="exportOrderParams()"/>
<!--              <el-button icon="el-icon-download" type="primary" @click="exportExcel" v-if="permission.includes('sys:order:export')">导出</el-button>-->
            </el-form-item>
          </el-form>
          <!-- 数据表格 -->
          <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true highlight-current-row >
            <template slot-scope="{index}">
              <el-table-column type="selection" width="45" align="center" />
              <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
              <el-table-column prop="aid" label="地区编号"  show-overflow-tooltip width="140">
								<template slot-scope="{row}">
									{{row.aid?row.aid:'无'}}
								</template>
							</el-table-column>
              <el-table-column prop="name" label="管理员名称"  show-overflow-tooltip  width="240"/>
              <el-table-column prop="set_area" label="地区"  show-overflow-tooltip width="240"/>
              <el-table-column prop="real_name" label="真实姓名"  show-overflow-tooltip min-width="120"/>
              <el-table-column prop="create_time" label="充值时间"  show-overflow-tooltip  min-width="160">
                 <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
              </el-table-column>
              <el-table-column prop="price" label="充值金额 (元)"  show-overflow-tooltip  min-width="120"/>
              <el-table-column prop="type_name" label="充值方式"  show-overflow-tooltip  min-width="100"/>
            </template>
          </ele-data-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="消费记录列表" name="second">
        <el-card shadow="never">
          <!-- 搜索表单 -->
          <el-form :model="table1.where" class="ele-form-search d-flex"
                   @keyup.enter.native="$refs.table1.reload()" @submit.native.prevent>
            <el-form-item label="省:" label-width="35px">
              <el-select v-model="table1.where.pid"  @change="handleChangeProv(table1.where.pid)" placeholder='请选择省' class="input150" clearable>
                <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市:" label-width="35px">
              <el-select v-model="table1.where.cid"  @change="handleChangeCity(table1.where.cid)" placeholder='请选择市' class="input150" clearable>
                <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="县/区:" label-width="65px">
              <el-select v-model="table1.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" class="input150" clearable>
                <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="消费金额(元):" label-width="80px">
              <el-input v-model="table1.where.fee" placeholder="请输入消费金额" class="input163" clearable/>
            </el-form-item>
            <el-form-item label="开始时间:" label-width="80px">
              <el-date-picker style="width:163px" v-model="table1.where.create_time" type="date" placeholder="选择日期" clearable>
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:" label-width="80px">
              <el-date-picker  style="width:163px" v-model="table1.where.end_time" type="date" placeholder="选择日期" class="mr-20" clearable>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="$refs.table1.reload()" icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
              <el-button @click="(table1.where={})&&$refs.table1.reload()">重置</el-button>
              <download class="ele-btn-ico" label="导出" url="adminiaelist/adminExpensesExportExcel" :params="exportOrderParams1()"/>
<!--              <el-button icon="el-icon-download" type="primary" @click="exportExcel" v-if="permission.includes('sys:order:export')">导出</el-button>-->
            </el-form-item>
          </el-form>
          <!-- 数据表格 -->
          <ele-data-table ref="table1" :config="table1" :choose.sync="choose1" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
            <template slot-scope="{index}">
              <el-table-column type="selection" width="45" align="center" />
              <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
              <el-table-column prop="aid" label="地区编号"  show-overflow-tooltip width="140">
								<template slot-scope="{row}">
									{{row.aid?row.aid:'无'}}
								</template>
							</el-table-column>
              <el-table-column prop="name" label="管理员名称"  show-overflow-tooltip  width="150"/>
              <el-table-column prop="set_area" label="地区"  show-overflow-tooltip width="240"/>
              <el-table-column prop="real_name" label="真实姓名"  show-overflow-tooltip min-width="120"/>
              <el-table-column prop="phone" label="手机号"  show-overflow-tooltip min-width="120"/>
              <el-table-column prop="create_time" label="消费时间"  show-overflow-tooltip  min-width="120" />
              <el-table-column prop="fee" label="消费金额 (元)"  show-overflow-tooltip  min-width="120"/>
              <el-table-column prop="title" label="消费内容"  show-overflow-tooltip  min-width="120"/>
            </template>
          </ele-data-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="提现申请列表" name="three">
        <el-card shadow="never">
          <!-- 搜索表单 -->

          <!-- 数据表格 -->
          <ele-data-table ref="table2" :config="table2" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
            <template slot-scope="{index}">
              <el-table-column type="selection" width="45" align="center" fixed="left"/>
              <el-table-column type="index" :index="index" label="序号" width="60"  show-overflow-tooltip/>
              <el-table-column prop="aid" label="地区编号"  show-overflow-tooltip width="140">
								<template slot-scope="{row}">
									{{row.aid?row.aid:'无'}}
								</template>
							</el-table-column>
              <el-table-column prop="set_area" label="地区"  show-overflow-tooltip  width="150"/>
              <el-table-column prop="name" label="管理员名称"  show-overflow-tooltip width="240"/>
              <el-table-column prop="real_name" label="真实姓名"  show-overflow-tooltip min-width="120"/>
              <el-table-column prop="phone" label="手机号"  show-overflow-tooltip min-width="120"/>
              <el-table-column prop="price" label="提现金额 (元)"  show-overflow-tooltip  min-width="120"/>
              <el-table-column prop="card" label="提现账户"  show-overflow-tooltip  min-width="160" >
								<template slot-scope="{row}">
									{{row.card?row.card:'无'}}
								</template>
							</el-table-column>
              <el-table-column prop="create_time" label="申请时间"  show-overflow-tooltip  min-width="120"/>
            </template>
            <el-table-column label="操作" width="200px" align="center" :resizable="false"  fixed="right">
              <template slot-scope="{row}">
                <el-link @click="edit(row)" icon="el-icon-success" type="primary" :underline="false" v-if="permission.includes('sys:consumption.vue:index')">通过</el-link>
                <el-link @click="edit(row)" icon="el-icon-error" type="primary" :underline="false" v-if="permission.includes('sys:consumption.vue:index')">拒绝</el-link>
                <el-link @click="edit(row)" icon="el-icon-delete" type="primary" :underline="false" v-if="permission.includes('sys:consumption.vue:index')">删除</el-link>
              </template>
            </el-table-column>
          </ele-data-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="冻结资金列表" name="four">
        <el-card shadow="never">
          <!-- 搜索表单 -->

          <!-- 数据表格 -->
          <ele-data-table ref="table3" :config="table3" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row :stripe=true>
            <template slot-scope="{index}">
              <el-table-column type="selection" width="45" align="center" />
              <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
              <el-table-column prop="aid" label="地区编号"  show-overflow-tooltip width="100">
								<template slot-scope="{row}">
									{{row.aid?row.aid:'无'}}
								</template>
							</el-table-column>
              <el-table-column prop="set_area" label="地区"  show-overflow-tooltip  width="150"/>
              <el-table-column prop="name" label="管理员名称"  show-overflow-tooltip  width="180"/>
              <el-table-column prop="real_name" label="真实姓名"  show-overflow-tooltip min-width="100"/>
              <el-table-column prop="phone" label="手机号"  show-overflow-tooltip min-width="120"/>
              <el-table-column prop="title" label="标题"  show-overflow-tooltip  min-width="120"/>
              <el-table-column prop="sur_amount" label="申请解冻金额 (元)"  show-overflow-tooltip  min-width="120"/>
              <el-table-column prop="create_time" label="申请时间"  show-overflow-tooltip  min-width="120"/>
							<el-table-column label="操作" align="center" show-overflow-tooltip min-width="160">
								<template slot-scope="{row}">
									<el-link slot="reference" @click="handleFreeze(row,1)" icon="el-icon-remove-outline"
											type="primary" :underline="false" v-if="permission.includes('sys:adminiadlist_payment:success')">通过</el-link>
									<el-link slot="reference" @click="handleFreeze(row,4)" icon="el-icon-errer"
											type="danger" :underline="false" v-if="permission.includes('sys:adminiadlist_payment.vue:error')">拒绝</el-link>
								</template>
							</el-table-column>
            </template>
          </ele-data-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="短信消费记录列表" name="five">
        <el-card shadow="never">
          <!-- 搜索表单 -->

          <!-- 数据表格 -->
          <ele-data-table ref="table4" :config="table4" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
            <template slot-scope="{index}">
              <el-table-column type="selection" width="45" align="center" />
              <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
              <el-table-column prop="aid" label="地区编号"  show-overflow-tooltip width="140">
								<template slot-scope="{row}">
									{{row.aid?row.aid:'无'}}
								</template>
							</el-table-column>
              <el-table-column prop="set_area" label="地区"  show-overflow-tooltip width="240"/>
              <el-table-column prop="real_name" label="真实姓名"  show-overflow-tooltip min-width="120"/>
              <el-table-column prop="phone" label="手机号"  show-overflow-tooltip min-width="120"/>
              <el-table-column prop="price" label="消费金额 (元)"  show-overflow-tooltip  min-width="120"/>
              <el-table-column prop="create_time" label="消费时间"  show-overflow-tooltip  min-width="120"/>
              <el-table-column prop="title" label="消费内容"  show-overflow-tooltip  min-width="120"/>
            </template>
          </ele-data-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="语音消费记录列表" name="six">
        <el-card shadow="never">
          <!-- 搜索表单 -->

          <!-- 数据表格 -->
          <ele-data-table ref="table5" :config="table5" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
            <template slot-scope="{index}">
              <el-table-column type="selection" width="45" align="center" />
              <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
              <el-table-column prop="aid" label="地区编号"  show-overflow-tooltip width="140">
								<template slot-scope="{row}">
									{{row.aid?row.aid:'无'}}
								</template>
							</el-table-column>
              <el-table-column prop="set_area" label="地区" show-overflow-tooltip width="240"/>
              <el-table-column prop="real_name" label="真实姓名"  show-overflow-tooltip min-width="120"/>
              <el-table-column prop="phone" label="手机号"  show-overflow-tooltip min-width="120"/>
              <el-table-column prop="price" label="消费金额 (元)"  show-overflow-tooltip  min-width="120"/>
              <el-table-column prop="create_time" label="消费时间"  show-overflow-tooltip  min-width="120"/>
              <el-table-column prop="title" label="消费内容"  show-overflow-tooltip  min-width="120"/>
            </template>
          </ele-data-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="95128虚拟号消费列表" name="seven">
        <el-card shadow="never">
          <!-- 搜索表单 -->
          <el-form :model="table7.where" class="ele-form-search d-flex"
                   @keyup.enter.native="$refs.table7.reload()" @submit.native.prevent>
            <el-form-item label="开始时间：" label-width="110px">
              <el-date-picker :picker-options="pickerOptionsStart" style="width:300px"  popper-class="no-atTheMoment" v-model="table7.where.start_time"  value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间：" label-width="110px">
              <el-date-picker :picker-options="pickerOptionsEnd" style="width:300px"  popper-class="no-atTheMoment" v-model="table7.where.end_time" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期"></el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="$refs.table7.reload()" icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
              <el-button @click="(table7.where={})&&$refs.table7.reload()">重置</el-button>
            </el-form-item>
          </el-form>
          <!-- 数据表格 -->
          <ele-data-table ref="table7" :config="table7" :choose.sync="choose7" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
            <template slot-scope="{index}">
              <el-table-column type="selection" width="45" align="center" />
              <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
              <el-table-column prop="set_area" label="地区"  show-overflow-tooltip min-width="120px"/>
              <el-table-column prop="role_name" label="管理员"  show-overflow-tooltip min-width="120px"/>
              <el-table-column prop="real_name" label="真实姓名"  show-overflow-tooltip  min-width="120px"/>
              <el-table-column prop="mobile" label="手机号"  show-overflow-tooltip min-width="120px"/>
              <el-table-column prop="price"  label="消费金额（元 ）"  show-overflow-tooltip  min-width="120px"/>
              <el-table-column prop="create_time"  label="消费时间"  show-overflow-tooltip  min-width="120px"/>
              <el-table-column prop="type_name" label="消费方式"  show-overflow-tooltip min-width="120px"/>
              <el-table-column prop="package_title" label="消费内容"  show-overflow-tooltip min-width="120px"/>
            </template>
          </ele-data-table>
        </el-card>
      </el-tab-pane>

    </el-tabs>

  </div>
</template>

<script>
  import download from '@/components/Download/index'


  import { mapGetters } from "vuex";

export default {
  name: "SysUser",
  components: {download},
  data() {
    return {
      table: {url: '/adminiaelist/admin_recharge_record', where: {}},//充值记录
      table1: {url: '/adminiaelist/admin_expenses_record', where: {}},//消费记录
      
      table2: {url: '/adminiaelist/withdrawal_list', where: {}},//提现申请列表
      table3: {url: '/adminiaelist/frozen_funds_list', where: {}},//冻结资金列表
      
      table4: {url: '/adminiaelist/admin_sms_list', where: {}},//短信消费记录列表
      table5: {url: '/adminiaelist/admin_voice_list', where: {}},//语音通话消费记录列表
      table7: {url: '/VmRechargeOrder/index', where: {}},//95128语音消费记录
      choose: [],  // 表格选中数据
      choose1: [],  // 表格选中数据
      choose7: [],  // 表格选中数据
      editForm: {},  // 表单数据
      checkForm:{},
      dialogTableVisibleCheck:false,
      dialogTableVisibleEdit:false,
      dialogTableVisibleUrgent:false,
      provArr:[],
      cityArr:[],
      districtArr:[],
      urgentList:[],
      imageUrl:false,
      headImg:'',
      activeName:'first',
      dialogFormVisibleAddReturnActivity:false,
      row:[{
        state:'开启'
      },
        {
          state:'关闭'
        }],

      pickerOptionsStart: {
        disabledDate: time => {
          let endDateVal = this.tableend_time;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime();
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          let beginDateVal = this.tableCreate_time;
          if (beginDateVal) {
            return (
                time.getTime() <
                new Date(beginDateVal).getTime()
            );
          }
        }
      },
    }
  },
  created(){
    this.$http.get('/common/province_list').then(res=>{
      let data=JSON.parse(res.data)
      //console.log('province----list',data);
      this.provArr=data
    })
    setTimeout(() => {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    }, 300)
  },

  computed: {
    ...mapGetters(["permission"]),
  },
  mounted() {
  },
  methods: {
    exportOrderParams() {
      const query = this.table.where
      // const params = this.params
      const selectionIds = this.choose.map(item => {
        return item.id
      })

      return {ids: selectionIds, ...query}
    },
    exportOrderParams1() {
      const query = this.table1.where
      // const params = this.params
      const selectionIds = this.choose1.map(item => {
        return item.id
      })

      return {ids: selectionIds, ...query}
    },
		
		// 冻结
		handleFreeze(row,zt) {
			if(zt == 1){
				this.$confirm('您确定要解冻此活动?', '提示', {
					type: 'warning',
					confirmButtonText: '通过',
					cancelButtonText: '取消',
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/fiance/update_freeze', {
						id: row.id,
						freeze_id:row.freeze_id,
						zt: zt
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			}
			if(zt == 4){
				this.$confirm('您确定拒绝该申请?', '提示', {
					type: 'warning',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/fiance/update_freeze', {
						id: row.id,
						freeze_id:row.freeze_id,
						zt: zt
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			}
		},

    /**
     *选择省
     **/
    handleChangeProv(e){
      /** 获取被选省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table.where.cid=''
        this.table.where.aid=''



      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.table.where.aid=''
      })
			}else{
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
    },
    pause_start(row){
      if(row.zt==0){
        this.$confirm('确定暂停活动吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/finance/return_money_switch', {id:row.id,zt:0}).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: '暂停成功'});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })
      }else if(row.zt==1){
        this.$confirm('确定开启活动?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/finance/return_money_switch', {id:row.id,zt:1}).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: '开始成功'});
              this.$refs.table.reload();
              this.pauseStart='暂停'
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })
      }
    },
    switch_start(row){

      this.$confirm('确定关闭活动吗?', '提示', {type: 'warning'}).then(() => {
        const loading = this.$loading({lock: true});
        this.$http.post('/finance/return_money_switch', {id:row.id,zt:2}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: '关闭成功'});
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      })

    },


    //  保存邀请返现活动
    save() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({lock: true});
          if(this.pid){
            this.editForm.pid=this.pid
          }
          if(this.cid){
            this.editForm.cid=this.cid
          }
          if(this.aid){
            this.editForm.aid=this.aid
          }

          //console.log(this.editForm)
          this.$http.post('/finance/save_returnMoney', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },


  }
}
</script>

<style scoped>
.edit /deep/.el-form-item__content{
  margin-left:0 !important;
}
.ele-block .el-upload-dragger {
  width: 100%;
}
.changeStyle{
  width:150px;margin-right:30px;
}
.ele-body{
  padding:15px;
}
.el-upload-list el-upload-list--text{display:none !important}
/deep/.el-tabs__item{
  padding:0 20px !important;
  font-size: 15px;
}
/deep/.el-tabs__nav-scroll{
  background: white !important;
  padding-top: 10px;
}
.boxTitle{padding:8px}
.orderImgBox{margin-left: 10px;height: 80px;}
.orderImgBox span{
  font-size: 36px;
}
.user-info-tabs >>> .el-tabs__nav-wrap {
  padding-left: 20px;
}
.innerBox{
  width:60%;
  margin: 0 auto;
  padding-top: 20px;
  border-bottom: 1px rgb(240,240,240) solid;
}
.innerBoxTo{
  width:60%;
  margin: 0 auto;
  padding-top: 20px;
}
.innerTitle{
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 13px;
}
.inputWidth{
  width:100%;
}
</style>

